<template>
  <div class="register">
    <div class="container">
      <div class="title">
        Sign up to <img :alt="appName"
           :src="require('@/assets/images/logos/pulsetic.svg')" />
      </div>

      <div class="register-card">
        <auth-via-google title="Sign up with Google"
                         class="mb-4"/>

        <div class="alternative-register">
          <span>OR</span>
        </div>

        <ValidationObserver v-slot="{ passes }" slim>
          <form ref="registerForm"
                @submit.prevent="passes(runCaptcha)">
            <base-textbox label="Email"
                          ref="email"
                          inputmode="email"
                          rules="required|email|uniqueEmail"
                          v-model="credentials.email"
                          placeholder="Enter your email address" />

            <base-textbox label="Password"
                          type="password"
                          rules="required|min:6"
                          v-model="credentials.password"
                          :show-password-toggle="true"
                          placeholder="Enter your password" />

            <div class="d-flex justify-content-between">
              <base-button type="submit"
                           class="register-button mr-2 flex-grow-1 flex-basis-0"
                           color="primary"
                           size="big"
                           :loading="loading">Continue ⟶</base-button>

              <router-link class="BaseButton light login-button is-outlined ml-2 flex-grow-1 flex-basis-0"
                         :to="{ name: 'login' }">Log In Instead</router-link>
            </div>
          </form>
        </ValidationObserver>

        <base-alert v-if="showLoginError"
                    class="alert-register-error"
                    type="error">The email or password is not correct.</base-alert>

        <div class="terms">
          By signing up you agree to the <a href="https://pulsetic.com/terms/" target="_blank">Terms of Service</a>
          and <a href="https://pulsetic.com/privacy/" target="_blank">Privacy Policy</a>
        </div>

        <div class="d-none">
          <vue-recaptcha ref="recaptcha"
                       size="invisible"
                       :sitekey="recaptchaKey"
                       @verify="onSignUpFormSubmit"
                       @expired="onCaptchaExpired" />
        </div>
      </div>

      <div class="signup-footer">
        <router-link :to="{ name: 'login' }">Login</router-link> &nbsp; • &nbsp; <a href="https://pulsetic.com/terms/">Terms</a> &nbsp; • &nbsp; <a href="https://pulsetic.com/privacy/">Privacy</a>
      </div>

      <recaptcha-disclaimer />
    </div>

    <a class="back-to-home" href="https://pulsetic.com/">←  To <span>Home Page</span></a>
  </div>
</template>

<script>
import AuthViaGoogle from '@/components/Auth/AuthViaGoogle.vue'
import VueRecaptcha from 'vue-recaptcha'
import RecaptchaDisclaimer from '@/components/Auth/AuthRecaptchaDisclaimer.vue'

export default {
  components: {
    AuthViaGoogle,
    VueRecaptcha,
    RecaptchaDisclaimer
  },

  metaInfo: {
    title: 'Register'
  },

  data () {
    return {
      credentials: {
        email: '',
        password: ''
      },

      showLoginError: false,
      loading: false,

      recaptchaKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,

      appName: process.env.VUE_APP_BRAND_NAME
    }
  },

  mounted () {
    this.$refs.email.focus()
  },

  methods: {
    runCaptcha () {
      this.loading = true
      console.log('loading')
      this.$refs.recaptcha.execute()
    },

    async onSignUpFormSubmit (recaptcha_token) {
      try {
        const response = await this.register({
          ...this.credentials,
          ...{ recaptcha_token }
        })
        if (response.status === 200) {
          // pulsetic.firstpromoter.com
          window.fpr('referral', {
            email: response.data.user.email
          })
        }

        window.location.href = '/'
      } catch (e) {
        this.showLoginError = true
      }

      this.loading = false
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },

    ...mapActions('authentication', [
      'register'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    //background-image: linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%);
    //background-size: cover;
    background: #f9f9f9;

    .container {
      max-width: 446px;
      padding: 0px;
    }

    // @media (min-width: 1000px) {
    //   .container {
    //     max-width: 446px;
    //     padding: 0;
    //   }
    // }

    @media (max-width: 500px) {
      .container {
        width: 100%;
        max-width: none;
        padding: 0 20px;
      }
    }

    .register-card {
      // display: flex;
      // height: 600px;
      // margin: 0 auto 10px;

      padding: 40px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 10px 30px rgba(0, 0, 0, 0.05);
      border: 1px solid #E4E8ED;
    }

    .title {
      // display: flex;
      // align-items: center;
      margin-bottom: 20px;

      font-size: 21px;
      font-weight: 500;

      width: 100%;
      text-align: center;
      letter-spacing:  -0.017em;

      img {
        height: 28px;
        vertical-align: text-bottom;
      }
    }

    .signup-button {
      width: 200px;
    }

    .alert-register-error {
      margin-top: 20px;
    }

    .alternative-register {
      background-image: linear-gradient(90deg,#e4e8ed 33%,#e4e8ed 0);
      background-position: bottom;
      background-size: 1px 1px;
      background-repeat: repeat-x;
      text-align: center;
      height: 11px;
      margin-bottom: 30px;

      span {
        background: #fff;
        padding: 0 12px;
        color: #aaa;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .back-to-home {
      color: #333;
      font-size: 13px;
      font-weight: 500;
      position: absolute;
      top: 0;
      left: 0;
      padding: 22px;
      span {
        font-weight: 700;
      }

      &:hover {
        color: map-get($colors, purple-1);
      }
    }

    .flex-basis-0 {
      flex-basis: 0;
    }

    .already-have-account {
      height: 30px;
      margin-top: auto;
    }

    .terms {
      margin-top: 20px;
      color: #999;
      font-size: 14px;
      line-height: 1.4;

      a {
        color: #999;
        border-bottom: 1px solid #999;

        &:hover {
          color: map-get($colors, purple-1);
          border-bottom: 1px solid map-get($colors, purple-1);
        }
      }
    }

    .signup-footer {
      font-size: 14px;
      color: #828282;
      text-align: center;
      padding: 30px;

      a {
        color: #828282;

        &:hover {
          color: map-get($colors, purple-1);
        }
      }
    }
  }
</style>
